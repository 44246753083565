<template>
	<div class="dataset" @mouseleave="handleDataObjectAutoSave">
		<div v-if="!(activeDataObject && activeDataObject._id)" class="dataset__form">
			<FormConnectText />
		</div><!-- /.dataset__form -->

		<div v-else class="dataset__form">

			<p v-if="textReferences.length > 1" class="dataset-references">
				<Icon name="references" />
				
				<em>{{ multipleReferencesText }}</em>

				<Dropdown ref="textReferencesDropdown" placement="bottom-end">
					<template #header>
						<span class="dropdown__arrow" />
					</template>

					<DropdownNavDatasets
						:dataObjects="textReferences"
						@dropdownButtonClick="handleDropdownButtonClick"
					/>
				</Dropdown>
			</p><!-- /.datasets-references -->
			
			<Form className="form--dataset" :loading="isFormSubmitting">
				<FormHead>
					<input
						type="text"
						id="name"
						tabindex="0"
						:placeholder="NameInputPlaceholder"
						:value="formData.name"
						v-tooltip.top="formData.name"
						@input="handleNameInputChange"
					/>

					<p>{{ dataType }}</p>
				</FormHead>
				
				<FormBody>
					<component
						:is="FormFields"
						:formData="formData"
						:kind="activeDataObject.kind"
						:readOnly="readOnly"
					/>
				</FormBody>

				<div class="form__cta">
					<div class="form__cta-row">
						<div 
								v-if="userRoleWeight >= 10"
								class="form__cta-col"
							>
							<Button
								:title="activeDataObject.status"
								@onClick.prevent="handleDataObjectSave"
							>
								Complete This {{this.activeDataObjectType ? this.activeDataObjectType : 'dataset' }}
							</Button>
						</div><!-- /.form__cta-col -->

						<div v-if="userRoleWeight < 100" class="form__cta-col">
							<Button
								className="tertiary"
								square
								:active="textPassagePopupVisibility()"
								v-tooltip.top="tooltips.passage"
								@onClick.prevent="toggleTextPassagePopup"
							>
								<Icon name="documents" />
							</Button>

							<Button
								className="tertiary"
								v-if="userRoleWeight >= 10"
								v-tooltip.top="tooltips.unlink"
								@onClick.prevent="openUnlinkModal"
							>
								<Icon name="unlink" />

								Unlink
							</Button>
							
							<Button
								className="tertiary"
								v-if="userRoleWeight >= 10"
								v-tooltip.top="tooltips.deleteText"
								@onClick.prevent="openDeleteModal"
							>
								<Icon name="trash" />

								Delete
							</Button>
						</div><!-- /.form-__cta-col -->

						<div v-if="userRoleWeight >= 100" class="form__cta-col" style="width: 100%;">
							<Button
								className="tertiary"
								square
								:active="sciscorePopupVisibility()"
								v-tooltip.top="tooltips.sciscore"
								@onClick.prevent="toggleSciscoreLogsPopup"
								:disabled="activeDataObject.kind === 'dataset' || activeDataObject.kind === 'protocol'"
							>
								<Icon name="sciscore" />
							</Button>

							<Button
								className="tertiary"
								square
								:active="this.formData.flagged"
								v-tooltip.top="tooltips.flag"
								@onClick.prevent="toggleIssues"
							>
								<Icon name="flag" />
							</Button>

							<Button
								className="tertiary"
								square
								:active="textPassagePopupVisibility()"
								v-tooltip.top="tooltips.passage"
								@onClick.prevent="toggleTextPassagePopup"
							>
								<Icon name="documents" />
							</Button>

							<Button
								square
								className="tertiary"
								modifier="is-warning"
								v-tooltip.top-center="tooltips.unlink"
								@onClick.prevent="openUnlinkModal"
							>
								<Icon name="unlink" />
							</Button>

							<Button
								className="tertiary"
								square
								modifier="is-warning"
								v-tooltip.top-center="tooltips.duplicateText"
								@onClick.prevent="handleDuplicateDataObject"
							>
								<Icon name="document_new" />
							</Button>

							<Button
								className="tertiary"
								square
								modifier="is-warning"
								v-tooltip.top-center="tooltips.deleteText"
								@onClick.prevent="openDeleteModal"
							>
								<Icon name="trash" />
							</Button>

							<Button
								style="float: right;"
								className="tertiary"
								square
								:active="dataObjectLogsPopupVisibility()"
								v-tooltip.top="tooltips.logs"
								@onClick.prevent="toggleDataObjectsLogsPopup"
							>
								<Icon name="document_view" />
							</Button>
						</div><!-- /.form-__cta-col -->
					</div> <!-- /.form__cta-row -->
				</div> <!-- /.form__cta -->
			</Form>

			<PopupDraggable ref="SciscorePopup" name="sciscore-popup" size="small">
				<Grid rowGap="medium">
					<GridColumn>
						<Field
							name="suggestedEntity"
							v-model.trim="formData.suggestedEntity"
						>
							<Icon name="windows" />

							Suggested Name of Entity
						</Field>
					</GridColumn>

					<GridColumn v-if="activeDataObject.kind !== 'reagent'">
						<Field
							name="suggestedURL"
							v-model.trim="formData.suggestedURL"
						>
							<Icon name="chain" />

							Suggested URL
						</Field>
					</GridColumn>

					<GridColumn>
						<Field
							name="suggestedRRID"
							v-model.trim="formData.suggestedRRID"
						>
							<Icon name="key" />

							Suggested RRID
						</Field>
					</GridColumn>

					<GridColumn>
						<div v-if="isAutoFillingSugdestedFields" class="list">
							<i>Automtically fill suggested fields...</i>
						</div>
						<ListOfRRIDs
							v-if="!isAutoFillingSugdestedFields"
							name="searchRRIDs"
							:entity="formData.searchRRIDs.entity"
							:error="formData.searchRRIDs.err"
							:results="formData.searchRRIDs.results"
							@clickOnRRID="handleCickOnRRID"
						>
							<div class="list">
								Suggested RRIDs from the<a href="https://scicrunch.org/" target="_blank"> Scicrunch API </a>
							</div>
						</ListOfRRIDs>
					</GridColumn>

					<GridColumn>
							<Button
								@onClick.prevent="$refs.SciscorePopup.hideModal()"
							>
								Done
							</Button>
					</GridColumn>	
				</Grid>
			</PopupDraggable>

			<PopupDraggable ref="TextPassagePopup" name="text-passage-popup" size="small">
				<RichtextEntry label="Connected Text Passages" icon="documents">
					<p v-for="sentence in activeDataObject.sentences" :key="sentence.id">
						{{sentence.text}}
					</p>
				</RichtextEntry>
			</PopupDraggable>

			<PopupDraggable ref="DataObjectLogsPopup" name="logs-popup" size="small" class="DataObjectLogsPopup">
				<div class="popup-container">
					<h4>DataObject Logs</h4>
					<div v-if="activeDataObjectLogs.loading">
						<i>Loading DataObject Logs...</i>
					</div>
					<div v-if="activeDataObjectLogs.err">
						{{ activeDataObjectLogs.err }}
					</div>
					<div v-if="!activeDataObjectLogs.loading && activeDataObjectLogs.list.length <= 0">
						This object has just been created and does not yet have any logs available
					</div>

					<DataObjectLogs
							:loading="activeDataObjectLogs.loading"
							:logs="activeDataObjectLogs.list"
							@clickOnRestoreDataObject="handleRestoreDataObject"
						>
					</DataObjectLogs>
				</div>
			</PopupDraggable>
		</div><!-- /.dataset__form -->
	</div><!-- /.dataset -->
</template>

<script>
/**
 * External Dependencies
 */
import { mapGetters, mapActions } from 'vuex'
import { updatedDiff } from 'deep-object-diff'
import async from 'async';

/**
 * Internal Dependencies
 */
import variables from '@/assets/scss/generic/_variables.scss'

import PopupDraggable from '@/components/popup/popup-draggable';
import PopupConfirm from '@/components/popup/popup-confirm';
import RichtextEntry from '@/components/richtext-entry/richtext-entry';
import Icon from '@/components/icon/icon'
import Button from '@/components/button/button'
import Field from '@/components/field/field';
import ListOfRRIDs from '@/components/list-rrids/list-rrids';
import DataObjectLogs from '@/components/dataobject-logs/dataobject-logs';
import Dropdown, { DropdownNavDatasets } from '@/components/dropdown/dropdown'
import Grid, { GridColumn } from '@/components/grid/grid';
import Form, { FormBody, FormHead } from '@/components/form/form';
import FormConnectText from '@/blocks/form-connect-text/form-connect-text';
import FormDataset from '@/blocks/form-dataset/form-dataset-default';
import FormDatasetCode from '@/blocks/form-dataset/form-dataset-code';
import FormDatasetMaterial from '@/blocks/form-dataset/form-dataset-material';
import FormDatasetProtocols from '@/blocks/form-dataset/form-dataset-protocols';
import { clearDropdown } from '@/utils/use-dropdowns';

import API from '@/lib/datasets/api';
import URLMANAGER from '@/lib/datasets/url';

export default {
	/**
	 * Name
	 */
	name: "Dataset",

	/**
	 * Components
	 */
	components: {
		Grid,
		GridColumn,
		Field,
		variables,
		Icon,
		Form,
		PopupDraggable,
		PopupConfirm,
		Button,
		Dropdown,
		DropdownNavDatasets,
		FormHead,
		FormBody,
		RichtextEntry,
		FormConnectText,
		ListOfRRIDs,
		DataObjectLogs
	},

	/**
	 * Props
	 */
	props: {
		dataObject: {
			type: Object,
			default: () => {}
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},

	/**
	 * Data
	 */
	data() {
		return {
			formData: {
				err: "Not available",
				searchRRIDs: {
					err: "",
					results: [],
					entity: {
						name: "",
						URL: ""
					}
				},
			},
			originalFormData: {},
			formDataIssues: {
				active: [''],
				author: '',
				createdAt: '',
				comment: '',
			},
			activeDataObjectLogs: {
				list: [],
				loading: false,
				error: "Service unavailable"
			},
			isAutoFillingSugdestedFields: false,
			isFormSubmitting: false,
			multipleReferencesText: 'Multiple references share this text selection',
			NameInputPlaceholder: 'Enter...',
			tooltips: {
				logs: 'Show logs of this object',
				sciscore: 'Suggest citation information for this object',
				flag: 'Flag Issues For Author',
				passage: 'Show Text Passage',
				unlink: 'Unlink selected sentence to this item',
				deleteText: 'Delete this item',
				duplicateText: 'Duplicate this item'
			},
			modals: {
				confirmDelete: {
					message: "Do you wish to permanently delete this item?",
					confirm: "Yes, Delete",
					cancel: "No, Keep It",
				},
				confirmUnlink: {
					message: "Do you wish to unlink the selected text passage from this item?",
					confirm: "Yes, Unlink",
					cancel: "No, Keep It",
				}
			},
			issuesList: [
				'URL broken',
				'Input incorrect (wrong cat#/RRID/PID/DOI/other)',
				'Item not yet publicly accessible',
				'Not an appropriate reference',
				'Dataset not provided',
				'Other'
			]
		}
	},

	/**
	 * Watch
	 */
	watch: {
		activeDataObjectId: {
			handler() {
				if (!this.activeDataObjectId) {
					this.$refs.TextPassagePopup?.hideModal();
					this.$refs.DataObjectLogsPopup?.hideModal();
					this.$refs.SciscorePopup?.hideModal();
					return;
				}
				if (this.$refs.SciscorePopup?.isVisible) this.handleSciscoreClick();
				if (this.$refs.DataObjectLogsPopup?.isVisible) this.handleDataObjectLogsClick();
				this.populateFormData();
			},
			deep: true
		}
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('pdfViewer', [
			'documentHandler',
			'dataTypes',
			'activeDataObject',
			'activeDataObjectId',
			'activeDataObjectType',
			'activeSentence',
			'dataObjects'
		]),
		...mapGetters('account', ['userRoleWeight', 'user']),
		FormFields() {
			if (this.activeDataObjectType === 'code') return FormDatasetCode;
			if (this.activeDataObjectType === 'material') return FormDatasetMaterial;
			if (this.activeDataObjectType === 'protocol') return FormDatasetProtocols;

			return FormDataset;
		},
		dataType() {
			return this.dataObject?.dataType ? this.dataObject.dataType : 'Undefined Type'
		},
		textReferences() {
			return this.dataObjects.filter((dataObject) => dataObject.sentences.some((sentence) => sentence.id === this.activeSentence?.id));
		},
		cssVariables() {
			return variables
		}
	},

	/**
	 * Methods
	 */
	methods: {
		...mapActions('pdfViewer', [
			'deleteDataObject',
			'unlinkSentenceFromDataObject',
			'setActiveDataObject',
			'setActiveDataObjectType',
		]),
		textPassagePopupVisibility() {
			return !!this?.$refs?.TextPassagePopup?.isVisible;
		},
		sciscorePopupVisibility() {
			return !!this?.$refs?.SciscorePopup?.isVisible;
		},
		dataObjectLogsPopupVisibility() {
			return !!this?.$refs?.DataObjectLogsPopup?.isVisible;
		},
		populateFormData() {
			this.formData = { ...this.formData, ...this.activeDataObject };
			this.originalFormData = { ...this.originalFormData, ...this.activeDataObject };
		},
		handleNameInputChange(e) {
			this.formData = { ...this.formData, name: e.target.value }
			if (this.$refs.SciscorePopup?.isVisible) this.toggleSciscoreLogsPopup();
		},
		handleDropdownButtonClick(dataObject) {
			if (dataObject._id === this.activeDataObjectId) return

			// Close text references dropdown when the active dataObject changes
			clearDropdown(this.$refs.textReferencesDropdown.$el);
			
			this.setActiveDataObject({
				dataObject,
				scrollToSentence: true
			});
		},
		toggleTextPassagePopup() {
			if (this.$refs.TextPassagePopup.isVisible) this.$refs.TextPassagePopup.hideModal(); 
			else this.$refs.TextPassagePopup.showModal();
		},
		toggleDataObjectsLogsPopup() {
			if (this.$refs.DataObjectLogsPopup.isVisible) this.$refs.DataObjectLogsPopup.hideModal(); 
			else this.$refs.DataObjectLogsPopup.showModal();
			return this.handleDataObjectLogsClick();
		},
		toggleSciscoreLogsPopup() {
			if (this.$refs.SciscorePopup.isVisible) this.$refs.SciscorePopup.hideModal(); 
			else this.$refs.SciscorePopup.showModal();
			return this.handleSciscoreClick();
		},
		handleDataObjectLogsClick() {
			if (!this.$refs.DataObjectLogsPopup.isVisible) return;
			return API.dataObjects.getLogs({
				dataObject: this.activeDataObject,
			}, (err, query) => {
				if (err) {
					this.activeDataObjectLogs.error = err.toString();
					return;
				}
				if (query.err) {
					this.activeDataObjectLogs.error = query.err.toString();
					return;
				}
				this.activeDataObjectLogs.error = "";
				this.activeDataObjectLogs.list = [];
				let create = query.res.filter((item) => {
					return item.kind.key === "create";
				}).sort(function (a, b) {
					return new Date(a.date) - new Date(b.date);
				});
				let updates = query.res.filter((item) => {
					return item.kind.key === "update";
				}).sort(function (a, b) {
					return new Date(a.date) - new Date(b.date);
				});
				this.activeDataObjectLogs.list.push({
						_id: create[0]._id,
						account: create[0].account,
						dataObject: {
							old: create[0].state,
							new: create[0].state,
						},
						date : create[0].date
					});
				if (updates.length > 0) {
					for (let i = 0; i < updates.length; i++) {
						this.activeDataObjectLogs.list.push({
							_id: updates[i]._id,
							account: updates[i].account,
							dataObject: {
								old: updates[i].state,
								new: i + 1 < updates.length ? updates[i + 1].state : this.activeDataObject,
							},
							date : updates[i].date
						});
					}
				}
			});
		},
		handleSciscoreClick() {
			if (!this.$refs.SciscorePopup.isVisible) return;
			const documentHandler = this.documentHandler;
			const entity = { name: this.formData.name.toString(), URL: "" };
			this.formData.searchRRIDs = { err: "", results: [], entity: entity };
			let results = {};
			if (this.activeDataObject.kind !== "code" && this.activeDataObject.kind !== "software" && this.activeDataObject.kind !== "reagent") {
				this.formData.searchRRIDs.err = `Suggested RRIDs service is not available for DATASETS & PROTOCOLS`;
				return;
			}
			if (entity.name && entity.name.length > 0) {
				// Build results
				results.fromCustomList = { index: 0, text: "Custom List", href: URLMANAGER.buildURL(`${API.software.url}/customList`, {}, { origin: true }), entities: [], error: "Requesting service...", requestAPI: false };
				results.resources = { index: 1, text: "Resources", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.antibody = { index: 1, text: "Antibodies", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.tool = { index: 1, text: "Tools", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.plasmid = { index: 1, text: "Plasmid", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.organism = { index: 1, text: "Organisms", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.cellLine = { index: 1, text: "Cell Lines", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.biosamples = { index: 1, text: "Bio Samples", href: "", entities: [], error: "Requesting service...", requestAPI: false };
				results.fromAPI = { index: 2, text: "Scicrunch API", href: "", entities: [], error: "Requesting service...", requestAPI: true };
				// Set results depending active dataObject kind
				this.formData.searchRRIDs.results.push(results.fromAPI);
				if (this.activeDataObject.kind === `software` || this.activeDataObject.kind === `code`) {
					this.formData.searchRRIDs.results.push(results.fromCustomList);
					this.formData.searchRRIDs.results.push(results.tool);
				} else if (this.activeDataObject.kind === `reagent`) {
					this.formData.searchRRIDs.results.push(results.fromCustomList);
					this.formData.searchRRIDs.results.push(results.antibody);
					this.formData.searchRRIDs.results.push(results.plasmid);
					this.formData.searchRRIDs.results.push(results.organism);
					this.formData.searchRRIDs.results.push(results.cellLine);
				}
				return async.map([
					(next) => {
						if (this.activeDataObject.kind !== "code" && this.activeDataObject.kind !== "software") return next();
						return API.software.find({ name: entity.name }, (_, query) => {
							console.log(_, query);
							let error = "";
							let entities = [];
							if (_) error = "Custom software list is currently not available";
							if (!query.err && Array.isArray(query.res)) entities = query.res;
							results.fromCustomList.error = error;
							results.fromCustomList.entities = entities;
							return next();
						});
					},
					(next) => {
						if (this.activeDataObject.kind !== "reagent") return next();
						return API.reagents.find({ name: entity.name }, (_, query) => {
							console.log(_, query);
							let error = "";
							let entities = [];
							if (_) error = "Custom software list is currently not available";
							if (!query.err && Array.isArray(query.res)) entities = query.res;
							results.fromCustomList.error = error;
							results.fromCustomList.entities = entities;
							return next();
						});
					},
					(next) => {
						return documentHandler.getRRIDsFromSciscore(entity.name, (_, query) => {
							console.log(_, query);
							let error = "";
							if (_) error = "Suggested RRIDs service is currently not available";
							if (query.err) error = "Suggested RRIDs service has returned an error";
							results.fromAPI.error = error;
							// Build "links of API results" depending of the data object kind
							results.fromAPI.href = query?.res?.entity?.URLs?.API;
							results.resources.href = query?.res?.entity?.URLs?.resources;
							results.antibody.href = query?.res?.entity?.URLs?.antibody;
							results.tool.href = query?.res?.entity?.URLs?.tool;
							results.plasmid.href = query?.res?.entity?.URLs?.plasmid;
							results.organism.href = query?.res?.entity?.URLs?.organism;
							results.cellLine.href = query?.res?.entity?.URLs?.cellLine;
							results.biosamples.href = query?.res?.entity?.URLs?.biosamples;
							// Populate API results
							for (let key in query?.res?.RRIDs?.values) {
								results.fromAPI.entities.push({ href: query?.res?.RRIDs?.URLs?.resources[key], suggestedEntity: query?.res?.RRIDs?.values[key].Resource_Name, RRID: query?.res?.RRIDs?.values[key].scr_id })
							}
							return next();
						});
					},
					(next) => {
						return API.scicrunch.searchEntity({ entity: entity.name, kind: this.activeDataObject.kind }, (_, query) => {
							console.log(_, query);
							let error = "";
							if (_) error = "Suggested RRIDs service is currently not available";
							if (query.err) error = "Suggested RRIDs service has returned an error";
							results.resources.error = error;
							results.antibody.error = error;
							results.tool.error = error;
							results.plasmid.error = error;
							results.organism.error = error;
							results.cellLine.error = error;
							results.biosamples.error = error;

							results.resources.entities = query?.res?.resources;
							results.antibody.entities = query?.res?.antibody;
							results.tool.entities = query?.res?.tool;
							results.plasmid.entities = query?.res?.plasmid;
							results.organism.entities = query?.res?.organism;
							results.cellLine.entities = query?.res?.cellLine;
							results.biosamples.entities = query?.res?.biosamples;
							return next();
						});
					}
				], (fn, next) => {
					return fn(function() {
						return next();
					});
				}, () => {
				});
			} else {
				this.formData.searchRRIDs.results = [];
				this.formData.searchRRIDs.err = "You must provide a name to get suggested RRIDs";
			}
		},
		handleDataObjectAutoSave() {
			let formData = { ...this.formData };
			let originalFormData = { ...this.originalFormData };
			let updates = Object.keys(updatedDiff(originalFormData, formData));
			let needSave = updates.length > 0;
			if (needSave) this.handleDataObjectSave()
		},
		handleDataObjectSave() {
			if (this.userRoleWeight < 10) return;
			const documentHandler = this.documentHandler;
			this.isFormSubmitting = true;
			let formData = { ...this.formData };
			
			// Set issues property when user is curator
			if (this.userRoleWeight >= 100) {
				if (formData.flagged) {
					if (!formData.issues) {
						formData.issues = {};
						formData.issues.active = []
						formData.issues.author = this.user.username
						formData.issues.createdAt = new Date()
					} else {
						if (!Array.isArray(formData.issues.active)) formData.issues.active = []
						if (!formData.issues.author) formData.issues.author = this.user.username
						if (!(formData.issues.createdAt instanceof Date)) formData.issues.createdAt = new Date()
					}
					// No longer use these fields, clean code ASAP
					formData.issues.active = undefined;
					formData.issues.comment = undefined;
				} else {
					formData.issues = null
				}
			}

			documentHandler.saveDataObject(formData, (_, res) => {
				const newDataObject = res;
				this.formData = { ...this.formData, ...this.res }
				this.originalFormData = { ...this.formData, ...this.res }
				
				if (this.activeDataObjectType !== newDataObject.dataObjectType) {
					this.setActiveDataObjectType(newDataObject.dataObjectType);
				}

				this.setActiveDataObject({
					dataObject: newDataObject,
					scrollToSentence: false
				});

				this.isFormSubmitting = false;
			})
		},
		toggleIssues() {
			this.formData.flagged = !this.formData.flagged;
			this.handleDataObjectSave();
		},
		setIssuesFormVisibility(value) {
			this.formData.flagged = value;
		},
		handleDuplicateDataObject() {
			const documentHandler = this.documentHandler;
			this.isFormSubmitting = true;
			let formData = { ...this.formData };

			documentHandler.duplicateDataObject(formData, (_, res) => {
				const newDataObject = res;
				this.formData = { ...this.formData, ...this.res }
				this.originalFormData = { ...this.formData, ...this.res }
				
				if (this.activeDataObjectType !== newDataObject.dataObjectType) {
					this.setActiveDataObjectType(newDataObject.dataObjectType);
				}

				this.setActiveDataObject({
					dataObject: newDataObject,
					scrollToSentence: true
				});

				this.isFormSubmitting = false;
			})
		},
		openDeleteModal() {
			this.openConfirmModal({
				...this.modals.confirmDelete,
				onConfirm: this.deleteDataObject,
			})
		},
		openUnlinkModal() {
			this.openConfirmModal({
				...this.modals.confirmUnlink,
				onConfirm: this.unlinkSentenceFromDataObject,
			})
		},
		handleRestoreDataObject(dataObject) {
			return API.dataObjects.update({
				documentId: dataObject.document,
				dataObjects: [dataObject],
			}, (err, query) => {
				if (err) {
					this.activeDataObjectLogs.error = err.toString();
					return;
				}
				if (query.err) {
					this.activeDataObjectLogs.error = query.err.toString();
					return;
				}
				// add params selectedDataObjectId with dataObject id
				let url = new URL(location.href);
				let params = Object.assign({}, URLMANAGER.getParamsOfCurrentURL(), this.$route.query);
				if (url.searchParams.get('selectedDataObjectId') !== null) url.searchParams.delete('selectedDataObjectId');
				url.searchParams.append('selectedDataObjectId', dataObject._id);
				if (params.token) {
					if (url.searchParams.get('selectedDataObjectId') !== null) url.searchParams.delete('token');
					url.searchParams.append('token', params.token);
				}
				window.location.href = url.toString();
				// return window.location.reload();
			});
		},
		handleCickOnRRID(opts) {
			let data = opts.data;
			let requestAPI = opts.requestAPI;
			this.isAutoFillingSugdestedFields = true;
			// case Sciscrunch API must be required
			if (requestAPI) {
				return API.scicrunch.processRRID({ RRID: data.RRID }, (err, res) => {
					if (err) return alert("Service not available at the moment");
					if (res.err) return alert(`RRID ${data.RRID} not found`);
					this.formData.suggestedEntity = res.res.name;
					this.formData.suggestedURL = res.res.URL;
					this.formData.suggestedRRID = `${res.res.name} (RRID:${res.res.RRID})`;
					this.isAutoFillingSugdestedFields = false;
				});
			} else {
				// case data come from custom list of software
				this.formData.suggestedEntity = data.suggestedEntity;
				this.formData.suggestedURL = data.suggestedURL;
				this.formData.suggestedRRID = data.suggestedRRID;
				this.isAutoFillingSugdestedFields = false;
			}
		}
	},

	/**
	 * Created
	 */
	created () {
		this.populateFormData();
	},
}
</script>
